<template>
  <div>
    <b-card>
      <b-row align-h="between">
        <b-col
          md="1"
          sm="4"
          class="mb-2"
        >
          <b-form-select
            v-model="perPage"
            size="sm"
            :options="[10, 15, 20]"
          />
        </b-col>
        <b-col
          md="3"
          sm="2"
          class="mb-2"
        >
          <b-button
            v-b-modal.modal-filtro
            variant="warning"
          >
            Filtro
          </b-button>
        </b-col>
        <b-col
          md="8"
        >
          <b-form-input
            v-model="filter"
            size="sm"
            placeholder="Pesquisar"
          />
        </b-col>
      </b-row>
      <b-row>
        <!--Filtro criado por pendencias-->
        <b-col md="3">
          <label>Filtro por pendências</label>
          <v-select
            v-model="pendeciesFilter"
            :options="pendenciesFilterOptions"
            multiple
          />
          <br>
        </b-col>
        <!--Filtro criado por pendencias-->
        <b-col md="3">
          <label>Filtro por Status</label>
          <v-select
            v-model="statusFilter"
            :options="statusFilterOptions"
            multiple
          />
          <br>
        </b-col>
        <b-col
          cols="auto"
          class="mt-2"
        >
          <b-button
            variant="outline-warning"
            @click="exportFile"
          >
            <feather-icon
              icon="FileTextIcon"
            />
            Exportar excel
          </b-button>
        </b-col>
        <!--Filtro criado por pendencias-->

        <b-table
          responsive
          bordered
          striped
          small
          hover
          :filter="filter"
          :current-page="currentPage"
          :per-page="perPage"
          :items="newListFiltered"
          :fields="fieldsOperacoes"
          @filtered="onFiltered"
          @row-clicked="rowClicked"
        >
          <template #cell(sigla)="row">
            <b-badge
              pill
              :style="{background: row.item.color, color: '#fff',}"
            >
              {{ row.item.sigla }}
            </b-badge>
          </template>
          <template #cell(valor_nominal)="data">
            {{ data.item.valor_nominal ? valorBr(data.item.valor_nominal, true) : '' }}
          </template>
          <template #cell(ultimo_acordo)="data">
            <b-link
              v-if="data.item.ultimo_acordo && (data.item.ultimo_acordo.id || data.item.ultimo_acordo.status || data.item.ultimo_acordo.numero_acordo)"
              class="font-weight-bold d-block text-nowrap"
            >
              <b-badge
                pill
                :variant="resolveBadgeOperacao(data.item.ultimo_acordo.status).variant"
                :style="{ color: resolveBadgeOperacao(data.item.ultimo_acordo.status).color }"
              >
                {{ data.item.ultimo_acordo ? data.item.ultimo_acordo.status.toUpperCase().charAt(0) : '' }}
              </b-badge>
              {{ data.item.ultimo_acordo ? data.item.ultimo_acordo.numero_acordo : '' }}
            </b-link>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          align="center"
          size="sm"
        />
      </b-row>
      <b-overlay
        :show="load"
        rounded="sm"
      />
    </b-card>
    <b-modal
      ref="infoModal"
      title="Detalhes da Operação"
      size="xl"
      hide-footer
    >
      <info-modal
        :id-op="id"
      />
    </b-modal>
    <b-modal
      id="modal-filtro"
      title="Filtros"
      size="lg"
      cancel-title="Cancelar"
      cancel-variant="danger"
      ok-title="Filtrar"
      @ok="setFilter"
    >
      <b-row>
        <b-col md="12">
          <label for="credorName">Credor</label>
          <v-select
            id="credorName"
            v-model="credor.selected"
            multiple
            label="title"
            :options="credorOptions"
          />
        </b-col>
        <b-col
          md="6"
          class="mt-2"
        >
          <label>Período Vencimento</label>
          <b-row>
            <b-col cols="6">
              <b-form-input
                id="vencimento-inicio"
                v-model="vencimento.inicio"
                locale="pt"
                type="date"
              />
            </b-col>
            <b-col cols="6">
              <b-form-input
                id="vencimento-final"
                v-model="vencimento.final"
                locale="pt"
                type="date"
              />
            </b-col>
          </b-row>
        </b-col>

        <b-col
          lg="6"
          class="mt-2"
        >
          <b-row align-h="between">
            <b-col>
              <label>Status</label>
            </b-col>
            <b-col class="d-flex justify-content-end">
              <b-form-checkbox
                v-model="statusOp.exceto"
                value="1"
                unchecked-value="0"
                inline
                class="custom-control-primary"
              >
                Exceto
              </b-form-checkbox>
            </b-col>
          </b-row>
          <v-select
            v-model="statusOp.selected"
            label="title"
            :options="statusOp.options"
          />
        </b-col>
        <b-col
          lg="6"
          class="mt-2"
        >
          <b-row align-h="between">
            <b-col>
              <label>Classificação Negativação</label>
            </b-col>
            <b-col class="d-flex justify-content-end">
              <b-form-checkbox
                v-model="classNeg.exceto"
                value="1"
                unchecked-value="0"
                inline
                class="custom-control-primary"
              >
                Exceto
              </b-form-checkbox>
            </b-col>
          </b-row>
          <v-select
            v-model="classNeg.selected"
            label="title"
            :options="classNeg.options"
          />
        </b-col>
        <b-col
          lg="6"
          class="mt-2"
        >
          <b-row class="justify-content-between">
            <b-col>
              <label>Motivo Pendência</label>
            </b-col>
            <b-col class="d-flex justify-content-end">
              <b-form-checkbox
                v-model="mtPend.exceto"
                value="1"
                inline
                unchecked-value="0"
                class="custom-control-primary"
              >
                Exceto
              </b-form-checkbox>
            </b-col>
          </b-row>
          <v-select
            v-model="mtPend.selected"
            label="title"
            :options="mtPend.options"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BBadge,
  BCard,
  BCol,
  BRow,
  BTable,
  BFormInput,
  BPagination,
  BFormSelect,
  BModal,
  BOverlay,
  BButton,
  BFormCheckbox,
  BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { saveAs } from 'file-saver'
import axios from '@/../axios-auth'
import infoModal from '@/views/backoffice/operacao/cards/OperacoesInfo.vue'

const ExcelJS = require('exceljs')

export default {
  components: {
    BBadge,
    BCard,
    BCol,
    BRow,
    BTable,
    BFormInput,
    BPagination,
    BFormSelect,
    infoModal,
    BModal,
    vSelect,
    BOverlay,
    BButton,
    BFormCheckbox,
    BLink,
  },
  data() {
    return {
      filterOn: [],
      filter: '',
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      fieldsOperacoes: [
        { key: 'sigla', label: 'status', class: 'text-center' },
        { key: 'numero_operacao', label: 'operação', class: 'text-left' },
        { key: 'ultimo_acordo', label: 'acordo', class: 'text-left' },
        { key: 'pendencia', label: 'pendência', class: 'text-left' },
        { key: 'cliente', class: 'text-left', sortable: true },
        { key: 'data_vencimento', label: 'dt vencimento', class: 'text-center' },
        { key: 'data_processamento', label: 'dt processamento', class: 'text-center' },
        { key: 'valor_nominal', label: 'vl nominal', class: 'text-right' },
        { key: 'valor_atualizado', label: 'vl atualizado', class: 'text-right' },
        { key: 'desconto', class: 'text-right' },
      ],
      pendeciesFilter: [],
      id: null,
      itemsOperacoes: [],
      operacoes1: [],
      status_operacoes: [],
      itemsPendecies: [],
      load: false,
      statusFilter: [],
      // Opções do filtro
      credor: {
        credOption: [],
        selected: [],
        ids: [],
      },
      vencimento: {
        inicio: '',
        final: '',
      },
      statusOp: {
        selected: '',
        options: [],
        ids: [],
        exceto: '0',
      },
      mtPend: {
        selected: '',
        options: [],
        ids: [],
        exceto: '0',
      },
      classNeg: {
        selected: '',
        options: [],
        ids: [],
        exceto: '0',
      },
    }
  },

  computed: {
    // itens do select
    credorOptions() {
      return this.credor.credOption.sort((a, b) => {
        const nameA = a.title.toLowerCase()
        const nameB = b.title.toLowerCase()
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
    },
    pendenciesFilterOptions() {
      const { itemsPendecies } = this
      const operacoes = []
      itemsPendecies.map(item => {
        item.motivo_pendencia ? operacoes.push(item.motivo_pendencia.nome) : null
      })

      const pendenciesList = [...new Set(operacoes)]
      return pendenciesList
    },
    statusFilterOptions() {
      const itemsStatus = this.operacoes1
      const status = []
      itemsStatus.map(item => {
        item.status_operacao_nome ? status.push(item.status_operacao_nome) : null
      })

      const uniqueStatus = [...new Set(status)]
      return uniqueStatus
    },

    // Método que faz o filtro  retorna lista filtrada
    newListFiltered() {
      const { operacoes1, pendeciesFilter: pf, statusFilter: sf } = this
      let list = operacoes1

      if (pf.length > 0 || sf.length > 0) {
        list = []

        // Filtro por pendencia
        let newFilter = []
        const filterList = []
        list.length > 0 ? (newFilter = list) : (newFilter = operacoes1)
        for (let i = 0; i <= pf.length - 1; i++) {
          newFilter.map(item => {
            if (item.pendencia === pf[i]) {
              filterList.push(item)
            }
          })
          list = filterList
        }
        // Filtro por status
        let newFilterStatus = []
        const filterListStatus = []
        list.length > 0 ? (newFilterStatus = list) : (newFilterStatus = operacoes1)
        for (let i = 0; i <= sf.length - 1; i++) {
          newFilterStatus.map(item => {
            if (item.status_operacao_nome === sf[i]) {
              filterListStatus.push(item)
            }
          })
          list = filterListStatus
        }
      }

      const itemShow = list
      this.totalRows = itemShow.length
      return itemShow
    },
  },

  async created() {
    this.addColor()
    // await this.getOperacoes()
    await this.getCredores()
    await this.getStatusOperacao()
    await this.getMotivoPendencia()
    await this.getClassificacaoNegativacao()
    this.totalRows = this.itemsPendecies.length
  },

  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    rowClicked(item) {
      this.id = item.id
      this.$refs.infoModal.show()
    },
    async getCredores() {
      const credor = JSON.parse(localStorage.getItem('credores'))
      credor.map(item => {
        this.credor.credOption.push({
          id: item.id,
          title: item.nome,
        })
      })
    },
    async getStatusOperacao() {
      await axios.get('api/v1/info/statusoperacao', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(dt => (
          this.statusOp.options.push({
            title: dt.nome,
            id: dt.id,
          })
        ))
      })
    },
    async getMotivoPendencia() {
      await axios.get('api/v1/info/motivopendencia', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(dt => (
          this.mtPend.options.push({
            title: dt.nome,
            id: dt.id,
          })
        ))
      })
    },
    async getClassificacaoNegativacao() {
      await axios.get('api/v1/info/classificacaonegativacao', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(dt => (
          this.classNeg.options.push({
            title: dt.nome,
            id: dt.id,
          })
        ))
      })
    },
    async setFilter() {
      this.load = true
      this.loadOperacoes = false
      const body = {
        pag: 1,
        item_pag: 15,
        filtros: {
        },
      }
      // VALIDACAO CREDOR
      if (this.credor.selected.length) {
        this.credor.ids = []
        // eslint-disable-next-line array-callback-return
        await this.credor.selected.map(item => {
          this.credor.ids.push(item.id)
        })
        body.filtros.credores = this.credor.ids
      }

      // VALIDACAO VENCIMENTO
      if (this.vencimento.inicio && this.vencimento.final) {
        body.filtros.vencimento = {
          data_inicial: this.vencimento.inicio,
          data_final: this.vencimento.final,
        }
      }

      // VALIDACAO STATUS
      if (this.statusOp.selected) {
        body.filtros.status_operacao = {
          valor: this.statusOp.selected.id,
          // eslint-disable-next-line radix
          exceto: parseInt(this.statusOp.exceto),
        }
      }

      // VALIDACAO PENDENCIA
      if (this.mtPend.selected) {
        body.filtros.motivo_pendencia = {
          valor: this.mtPend.selected.id,
          // eslint-disable-next-line radix
          exceto: parseInt(this.mtPend.exceto),
        }
      }

      // VALIDCAO CLASSIFICACAO NEGATIVACAO
      if (this.classNeg.selected) {
        body.filtros.classificacao_negativacao = {
          valor: this.classNeg.selected.id,
          // eslint-disable-next-line radix
          exceto: parseInt(this.classNeg.exceto),
        }
      }
      if (Object.keys(body.filtros).length === 0) {
        this.$swal({
          title: 'Error!',
          text: 'Selecione pelo menos 1 filtro ',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        await axios.post('api/v1/operacoes/operacoesCredor', body, {
          headers: {
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
            accept: 'application/json',
          },
        }).then(res => {
          this.totalRows = ''
          this.operacoes = []
          this.itemsOperacoes = []

          if (res.status === 200) {
            // eslint-disable-next-line array-callback-return
            res.data.dados.operacoes.map(dt => {
              this.itemsOperacoes.unshift({
                id: dt.id,
                sigla: dt.status_operacao.sigla,
                numero_operacao: dt.numero_operacao,
                cliente: dt.cliente.nome,
                data_vencimento: dt.data_vencimento ? this.dataHora(dt.data_vencimento, true) : '',
                data_processamento: dt.data_processamento ? this.dataHora(dt.data_processamento, true) : '',
                valor_nominal: dt.valor_nominal,
                valor_atualizado: dt.valor_atualizado ? this.valorBr(parseFloat(dt.valor_atualizado), true) : '',
                descricao: dt.descricao,
                desconto: dt.desconto,
                pendencia: dt.motivo_pendencia ? dt.motivo_pendencia.nome : dt.motivo_pendencia,
                status: dt.status_operacao.nome,
                cpf_cnpj: dt.cliente.cpf_cnpj,
                aluno: dt.aluno,
                cliente_id: dt.cliente_id,
                remessa_id: dt.remessa_id,
                status_operacao: dt.status_operacao.id,
                status_operacao_nome: dt.status_operacao.nome,
                ultimo_acordo: dt.ultimo_acordo,
              })
            })
            this.load = false
            this.totalRows = this.itemsOperacoes.length
            this.itemsPendecies = res.data.dados.operacoes
            this.changeColor()
          }
        }).catch(() => {
          this.$swal({
            title: 'Error!',
            text: ' Erro ao carregar os dados do filtro!',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.load = false
        })
      }
    },
    async addColor() {
      await axios.get('api/v1/info/statusoperacao', {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(res => {
        this.status_operacoes = {}
        const colors = {
          C: '#B22222',
          L: '#66CDAA',
          D: '#696969',
          N: '#836FFF',
          A: '',
        }
        res.data.dados.map(dt => {
          this.status_operacoes[dt.sigla] = {
            sigla: dt.sigla,
            color: colors[dt.sigla],
          }
        })
      })
    },

    async changeColor() {
      this.operacoes1 = []
      await this.itemsOperacoes.map(dt => {
        const { sigla, color } = this.status_operacoes[dt.sigla]
        this.operacoes1.push({
          id: dt.id,
          sigla,
          color,
          pendencia: dt.pendencia,
          numero_operacao: dt.numero_operacao,
          cliente: dt.cliente,
          credor: dt.credor,
          data_vencimento: dt.data_vencimento,
          data_processamento: dt.data_processamento,
          valor_nominal: dt.valor_nominal,
          valor_atualizado: dt.valor_atualizado,
          descricao: dt.descricao,
          desconto: dt.desconto,
          status: dt.status,
          cpf_cnpj: dt.cpf_cnpj,
          aluno: dt.aluno,
          cliente_id: dt.cliente_id,
          remessa_id: dt.remessa_id,
          status_operacao: dt.status_operacao,
          status_operacao_nome: dt.status_operacao_nome,
          ultimo_acordo: dt.ultimo_acordo,
        })
      })
    },
    async exportFile() {
      const file = await this.getDataToExported()
      if (file) {
        saveAs(file)
      }
    },
    async getDataToExported() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Relatório')
      const itemsPlan = this.newListFiltered

      worksheet.columns = [
        { header: 'ALUNO', key: 'aluno' },
        { header: 'CLIENTE ID', key: 'cliente_id' },
        { header: 'CLIENTE', key: 'cliente' },
        { header: 'CPF/CNPJ CLIENTE', key: 'cpf_cnpj' },
        { header: 'DATA VENCIMENTO', key: 'data_vencimento' },
        { header: 'NÚMERO OPERAÇÃO', key: 'numero_operacao' },
        { header: 'NÚMERO ACORDO', key: 'ultimo_acordo' },
        { header: 'STATUS ACORDO', key: 'ultimo_acordo' },
        { header: 'STATUS OPERAÇÃO', key: 'status_operacao' },
        { header: 'STATUS PENDÊNCIA', key: 'pendencia' },
        { header: 'VALOR NOMINAL', key: 'valor_nominal' },
        { header: 'ID REMESSA', key: 'remessa_id' },
        { header: 'DESCRIÇÃO', key: 'descricao' },
      ]
      const wk1Columns = [
        'valor_nominal',
      ]
      const totais = {
        wk1: {},
      }
      itemsPlan.map(item => {
        worksheet.addRow([
          item.aluno,
          item.cliente_id,
          item.cliente,
          item.cpf_cnpj,
          item.data_vencimento,
          item.numero_operacao,
          item.ultimo_acordo ? item.ultimo_acordo.numero_acordo : '',
          item.ultimo_acordo ? item.ultimo_acordo.status : '',
          item.status_operacao_nome,
          item.pendencia ? item.pendencia : '',
          item.valor_nominal,
          item.remessa_id,
          item.descricao,
        ])
        wk1Columns.map(w => {
          totais.wk1[w] ? totais.wk1[w] += parseFloat(item[w]) : totais.wk1[w] = parseFloat(item[w])
        })
      })

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })
      const totalRowWk1 = worksheet.addRow({
        valor_nominal: totais.wk1.valor_nominal,
      })
      totalRowWk1.font = {
        name: 'Calibri',
        family: 2,
        bold: true,
        size: 11,
      }
      totalRowWk1.eachCell({ includeEmpty: false }, cell => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F4A460' },
        }
        cell.alignment = {
          horizontal: 'center',
          vertical: 'center',
        }
      })
      wk1Columns.map(key => {
        worksheet.getColumn(`${key}`).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00'
      })

      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], 'Credor Operação', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },
    resolveBadgeOperacao(status) {
      const response = {
        variant: '',
        color: '#fff',
      }
      if (status) {
        switch (status.toLowerCase()) {
          case 'liquidado':
            response.variant = 'success'
            break
          case 'negociado':
            response.variant = 'primary'
            break
          case 'cancelado':
            response.variant = 'danger'
            break
          case 'aguardando':
            response.variant = 'warning'
            break
          case 'quebrado':
            response.variant = 'danger'
            break
          case 'vencido':
            response.variant = 'secondary'
            break
          default:
            response.variant = 'secondary'
        }
      }
      return response
    },
  },
}
</script>
